<template>
	<div id="container" class="container">
		<div id="sub" class="sub map">
			<!-- [D] 지역 선택 -->
			<div style="display:block" class="layer">
				<div class="layer__full layer__map">
					<div class="layer__fullbox">
						<div class="layer__fullheader">
							<nav class="nav nav--fit">
								<div class="nav__wrap">
									<a href="#" class="nav__btn">
										<i class="icon icon-close"></i>
									</a>
									<p class="nav__item">
										<span class="nav__text">
											레저 티켓 표시
										</span>
									</p>
									<a class="nav__btn">
									</a>
								</div>
							</nav>
						</div>
						<div class="layer__fullbody">
							<div class="map__wrap">
								<!-- 지도 -->
								<div class="map__box">
								</div>
								<!-- 맵 마커 (스타일형 - 활성화시 active 클래스 추가) -->
								<button type="button" class="map__target active">
									50,000
								</button>
								<!-- 맵 마커 (스타일형 - 활성화시 active 클래스 추가) -->
								<button type="button" class="map__target map__target--2">
									60,000
								</button>
								<!-- 맵 마커 (인라인형) -->
								<div type="button" style="position:absolute;left:40%;top:40%;padding:2px 8px;line-height:18px;background-color:#ffffff;color:#000000;font-size:12px;border-radius:12px;box-shadow:1px 3px 3px rgba(0,0,0,0.2);box-sizing:border-box">
									70,000
								</div>
								<!-- 맵 마커 (인라인형 - 활성화 상태) -->
								<button type="button" style="position:absolute;left:70%;top:70%;padding:2px 8px;line-height:18px;background-color:#733CE6;color:#FFFFFF;font-size:12px;border-radius:12px;box-shadow:1px 3px 3px rgba(0,0,0,0.2);box-sizing:border-box">
									80,000
								</button>
                                <!-- layer -->
                                <div class="map__inbox">
                                    <div class="summary">
                                        <!-- summary image -->
                                        <div class="summary__thumbnail--small">
                                            <img src="@/assets/images/temp-img-item02.jpg" alt="">
                                        </div>
                                        <!-- summary item -->
                                        <div class="summary__itembox">
                                            <div class="summary__itemdata">
                                                <h3 class="summary__title info-title ellipsis">
                                                    역삼벤자민 한줄까지...asfasfasf asfasffsa
                                                </h3>
                                                <div class="summary__grad info-grad">
                                                    <span class="list__icon info-icon">
                                                        <i class="icon icon-star"></i>
                                                    </span>
                                                    <span class="summary__gradnum info-gradnum">
                                                        9.7
                                                    </span>
                                                        <span class="summary__gradcounter info-gradcounter">
                                                        (2,400)
                                                    </span>
                                                </div>
                                            </div>
                                            <ul class="summary__info">
                                                <li>
                                                    <div class="list__info-type">
                                                    </div>
                                                    <div class="list__info-price info-price">
                                                        <div class="info-price__current">
                                                            <strong>30,000</strong>원
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <!-- //layer -->
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>